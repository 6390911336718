.contact {
  position: relative;
  background-color: white;
  width: 80%;
  border-radius: 12px;
  text-align: Left;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  z-index: 1;
  padding: 1rem;
  top: 3rem;
  left: 10%;
}

.contact h2 {
  margin-left: 15%;
  color: rgb(0, 88, 240);
}

.background {
  max-width: 100%;
  position: relative;
  z-index: 0;
  left: 50%;
  top: 140px;
  transform: translateX(-50%);
  height: 700px;
  background-color: #e9e9e9;
}
.map {
  height: 400px;
  margin-bottom: 30px;
}
.visit {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 40%;
  margin-left: 15%;
  margin-right: 15%;
}
.visit h4 {
  color: rgb(0, 186, 254);
  margin-bottom: 30px;
}
.visit h1 {
  color: rgb(0, 88, 240);
}
.wave {
  width: 100%;
}
.infoContact {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.infobox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.iconContact {
  width: 20%;
}
.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .background {
    height: 700px;
  }
  .contact {
    padding: 1rem;
    width: 30rem;
    top: 3rem;
    left: 20%;
  }
  .contact h2 {
    margin-left: 13%;
  }
  .map {
    height: 400px;
  }
  .visit {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 23%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .infobox {
    width: 40%;
  }
}

@media (min-width: 992px) {
  .visit {
    display: flex;
    flex-direction: row;
    margin-top: 200px;
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media (min-width: 1200px) {
}
