.gradient-custom {
  /* fallback for old browsers */
  background: #6a11cb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    rgb(0, 37, 142),
    rgb(0, 170, 255)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, rgb(0, 37, 142), rgb(0, 170, 255));
}
.logoCellphone {
  width: 100px;
  margin-left: 80px;
  align-self: center;
}

@media (min-width: 992px) {
  .logoCellphone {
    display: none;
  }
}
.custom-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
}
