.logoCellphone {
  width: 100px;
  margin-left: 80px;
  align-self: center;
  margin-top: 30px;
}
.navbar-toggler {
  padding-left: 50%;
}
.logo {
  display: none;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .logoCellphone {
    display: none;
  }
  .logo {
    display: flex;
    width: 100px;
    align-self: center;
    justify-self: center;
    margin-right: 20px;
  }
  .homeOption {
    display: none;
  }
}
@media (min-width: 1200px) {
}
