.controls {
  width: 100%;
  margin-left: 5%;
  font-size: 12px;
}
.controls label {
  margin-bottom: 0.4rem;
  display: block;
  margin: 0.4rem;
  font-size: 1rem;
}

.controls input {
  padding: 0.4rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 85%;
}
.select {
  padding: 0.4rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 85%;
}
.message {
  height: 70px;
}
.action {
  margin: 1rem;
}

.invalid input {
  border-color: red;
  background: #ffd7d7;
}

.invalid label {
  border-color: red;
}
.button {
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

@media (min-width: 768px) {
  .controls {
    margin-left: 13%;
  }
  .controls input {
    max-width: 100%;
  }
}
