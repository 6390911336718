body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Home_homeHead__3mjlm {
  color: aliceblue;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  z-index: 3;
  padding: 15%;
  margin-top: 7rem;
}
.Home_homeHead__3mjlm h1 {
  color: aliceblue;
  font-size: 25px;
}
.Home_homeHead__3mjlm h5 {
  font-size: 15px;
}

.Home_message__2OaFq {
  margin-bottom: 5%;
}
.Home_aboutUs__1COAI {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}

.Home_mishisvis__IkkbV {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #e9e9e9;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.Home_mishisvis__IkkbV h4 {
  color: rgb(0, 88, 240);
}

.Home_servicesSection__3dW9f {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.Home_servicesSection__3dW9f h1 {
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 2%;
  color: #ff9100;
}
.Home_services__h8RPc {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}
.Home_servicesCard__15WX5 {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Home_servicesLink__2gvLj {
  display: flex;
  flex-direction: row;
}

.Home_servicesCard__15WX5 img {
  width: 35%;
  margin: 5%;
}
.Home_slogan__3u7Ng {
  margin: 5%;
  color: rgb(0, 88, 240);
  display: flex;
  align-items: center;
  justify-content: center;
}
.Home_slogan__3u7Ng img {
  width: 300px;
}
.Home_principalInfo__1QnEb {
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 30px;
}
.Home_description__34RaO {
  width: 80%;
}
.Home_description__34RaO h2 {
  color: #ff9100;
}
.Home_logoALROTEK__1ZqyH {
  width: 90%;
}
.Home_black__3dO-B {
  color: black;
  font-size: 0.9rem;
}

@media (min-width: 576px) {
  .Home_homeHead__3mjlm {
    height: 600px;
    padding: 15%;
  }
  .Home_homeHead__3mjlm h1 {
    font-size: 35px;
  }
  .Home_homeHead__3mjlm h5 {
    font-size: 17px;
  }
  .Home_aboutUs__1COAI {
    margin-top: 145px;
  }
}
@media (min-width: 768px) {
  .Home_homeHead__3mjlm {
    height: 700px;
  }
  .Home_homeHead__3mjlm h1 {
    font-size: 40px;
  }
  .Home_homeHead__3mjlm h5 {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .Home_homeHead__3mjlm {
    height: 800px;
    margin-top: 2rem;
  }
  .Home_aboutUs__1COAI {
    flex-direction: row;
    justify-content: space-between;
    margin-left: 15%;
    margin-right: 15%;
  }
  .Home_principalInfo__1QnEb {
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0px;
  }
  .Home_mishisvis__IkkbV {
    height: 450px;
    background-color: transparent;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .Home_logoALROTEK__1ZqyH {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .Home_homeHead__3mjlm {
    height: 900px;
    margin-top: 2rem;
  }
  .Home_aboutUs__1COAI {
    margin-bottom: 100px;
  }
  .Home_servicesCard__15WX5 img {
    width: 30%;
    margin: 3%;
  }
}

.Card_card__2U3P0 {
  padding: 1rem;
  border-radius: 6px;
  background-color: white;
  transition-duration: 0.4s;
  height: 220px;
  width: 360px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  margin-top: 50px;
}

.Card_card__2U3P0:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .Card_card__2U3P0 {
    height: 200px;
    width: 350px;
  }
}
@media (min-width: 992px) {
  .Card_card__2U3P0 {
    height: 200px;
    width: 350px;
  }
}

.Cardmenu_cardMenu__1DXd3 {
  border-radius: 6px;
  width: 300px;
  position: relative;
  text-align: center;
  margin: 20px;
  background-color: black;
}
.Cardmenu_cardMenu__1DXd3 h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
}
.Cardmenu_optionImage__bzczD {
  position: relative;
  top: 0;
  left: 0;
  width: 300px;
  height: auto;
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
}
.Cardmenu_optionIcon__D4naA {
  width: 70px;
  height: auto;
  position: absolute;
  z-index: 2;
  top: -30px;
  left: 100px;
}
.Cardmenu_infoOption__aBcas {
  position: absolute;
  top: 80px;
  z-index: 2;
  left: 80px;
}

@media (min-width: 992px) {
  .Cardmenu_infoOption__aBcas {
    z-index: 2;
    top: 125px;
    width: 200px;
    left: 15px;
  }
  .Cardmenu_cardMenu__1DXd3 {
    width: 450px;
    height: auto;
  }
  .Cardmenu_optionImage__bzczD {
    width: 450px;
  }
  .Cardmenu_titleOption__ZMc33 {
    width: 200px;
    margin-left: 50px;
  }
  .Cardmenu_optionIcon__D4naA {
    width: 100px;
    top: -54px;
    left: 260px;
  }
}

.Productmenu_menu__3jNE_ {
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.General_productMainInfo__1G0if {
  margin-top: 200px;
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  align-self: center;
  justify-self: center;
  justify-content: center;
}
.General_productMainInfo__1G0if h1 {
  color: rgb(3, 63, 167);
}
.General_productMainInfo__1G0if h4 {
  color: rgb(0, 186, 254);
}

.Productcard_card__2lOvr {
  padding: 1rem;
  margin: 1rem;
  border-radius: 6px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.Productcard_card__2lOvr img {
  width: 240px;
}
@media (min-width: 576px) {
  .Productcard_card__2lOvr {
    width: 40%;
    text-align: center;
  }
  .Productcard_card__2lOvr img {
    margin: 5%;
  }
}
@media (min-width: 768px) {
  .Productcard_card__2lOvr {
    width: 25%;
    text-align: center;
  }
}

.Category_store__1cCz9 {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: center;
}
.Category_store__1cCz9 h1 {
  display: flex;
  justify-self: center;
  align-self: center;
  margin-top: 15px;
  color: #ff9100;
}
.Category_products__CyDUl {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
@media (min-width: 992px) {
  .Category_store__1cCz9 h1 {
    margin-top: 40px;
  }
}

.Products_fireSystem__3Wc6C button {
  display: none;
}
.Products_tankFamilyMainInfo__2tVjT {
  margin-top: 170px;
}

.Products_familyMainInfo__1LYzt {
  margin-top: 170px;
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  align-self: center;
  justify-self: center;
  justify-content: center;
  text-align: center;
}
.Products_familyMainInfo__1LYzt h1 {
  color: rgb(3, 63, 167);
}
.Products_text__1ggTC {
  margin-top: 250px;
}
.Products_employeeSection__oEIen {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #c6c6c6;
}
.Products_employeeSection__oEIen h1 {
  color: rgb(3, 63, 167);
  font-size: 25px;
}
.Products_employeeSection__oEIen h4 {
  color: rgb(0, 186, 254);
  margin-bottom: 5%;
}
.Products_principal__2cbEM {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 576px) {
  .Products_familyMainInfo__1LYzt {
    margin-top: 200px;
  }
  .Products_employeeSection__oEIen h1 {
    font-size: 35px;
  }
}
@media (min-width: 768px) {
  .Products_employeeSection__oEIen h1 {
    font-size: 40px;
  }
}

.Template_pageCard__3itde {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 65px;
  justify-content: space-evenly;
  text-align: center;
  margin-top: 200px;
}
.Template_templateImg__2A3rP {
  width: 300px;
  margin-left: 5%;
  border-radius: 6px;
}
.Template_pageCard__3itde h1 {
  color: rgb(3, 63, 167);
  margin-bottom: 5%;
  font-size: 25px;
}
.Template_pageCard__3itde h4 {
  color: rgb(0, 186, 254);
  margin-bottom: 3%;
  font-size: 18px;
}
.Template_pageCard__3itde p {
  margin-bottom: 5%;
}
.Template_textInfo__Wj19R {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .Template_templateImg__2A3rP {
    width: 400px;
  }
  .Template_pageCard__3itde h1 {
    font-size: 35px;
  }
}
@media (min-width: 768px) {
  .Template_textInfo__Wj19R {
    width: 60%;
  }
  .Template_templateImg__2A3rP {
    width: 500px;
  }
  .Template_pageCard__3itde h1 {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .Template_pageCard__3itde {
    margin-top: 245px;
    flex-direction: row;
    text-align: left;
  }
  .Template_textInfo__Wj19R {
    width: 45%;
  }
  .Template_templateImg__2A3rP {
    width: 300px;
  }
  .Template_pageCard__3itde h4 {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .Template_templateImg__2A3rP {
    width: 500px;
  }
  .Template_pageCard__3itde h4 {
    font-size: 24px;
  }
}

.EmployeeCard_employeePhoto__Y4WYU {
  width: 250px;
  border-radius: 6px;
  margin: 10px;
}

.EmployeeCard_personalInfo__3h0IS {
  width: 250px;
}
.EmployeeCard_employeePhoto__Y4WYU:hover {
  overflow: visible;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 1s;
}
.EmployeeCard_employeeCard__s9fuz {
  width: 270px;
}

.footer_footer__3KE_W {
  color: white;
  background: linear-gradient(90deg, rgb(0, 37, 142) 50%, rgb(0, 170, 255));

  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}
.footer_contInfo__RJ389 {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;
  margin-top: 2%;
}
.footer_redlogo__274Qx {
  width: 35px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
}
.footer_info__1Pf7o {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  padding-right: 15%;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer_info__1Pf7o h2 {
  font-size: 20px;
}
.footer_info__1Pf7o p {
  line-height: 17px;
}
.footer_derechos__11jn8 {
  font-size: 12px;
}
.footer_socialMediaIcons__eeyp2 {
  display: flex;
  flex-direction: row;
}
@media (min-width: 576px) {
  .footer_footer__3KE_W {
    height: 300px;
  }
  .footer_info__1Pf7o {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  .footer_redlogo__274Qx {
    width: 50px;
  }
  .footer_derechos__11jn8 {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  .footer_info__1Pf7o h2 {
    font-size: 25px;
  }
  .footer_info__1Pf7o p {
    line-height: 24px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}

.socialMedia_instagram__3ietd {
  background-color: white;
  border-radius: 50%;
  bottom: 20px;
  right: 20px;
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin: 15px;
}

.socialMedia_icon__3oeoX {
  color: #0015ad;
  font-size: 27px;
}
.socialMedia_facebook__3UdzJ {
  margin: 15px;
}

.Layout_logoCellphone__2C0_6 {
  width: 100px;
  margin-left: 80px;
  align-self: center;
  margin-top: 30px;
}
.Layout_navbar-toggler__3tovP {
  padding-left: 50%;
}
.Layout_logo__2wzNQ {
  display: none;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .Layout_logoCellphone__2C0_6 {
    display: none;
  }
  .Layout_logo__2wzNQ {
    display: flex;
    width: 100px;
    align-self: center;
    justify-self: center;
    margin-right: 20px;
  }
  .Layout_homeOption__2UHfI {
    display: none;
  }
}
@media (min-width: 1200px) {
}

.gradient-custom {
  /* fallback for old browsers */
  background: #6a11cb;

  /* Chrome 10-25, Safari 5.1-6 */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, rgb(0, 37, 142), rgb(0, 170, 255));
}
.logoCellphone {
  width: 100px;
  margin-left: 80px;
  align-self: center;
}

@media (min-width: 992px) {
  .logoCellphone {
    display: none;
  }
}
.custom-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
}

.WhatsApp_whatsapp__12rNb {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #20d767;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 5;
}
.WhatsApp_icon__2YG53 {
  color: white;
  font-size: 35px;
}

.Contact_contact__1PWNs {
  position: relative;
  background-color: white;
  width: 80%;
  border-radius: 12px;
  text-align: Left;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  z-index: 1;
  padding: 1rem;
  top: 3rem;
  left: 10%;
}

.Contact_contact__1PWNs h2 {
  margin-left: 15%;
  color: rgb(0, 88, 240);
}

.Contact_background__6P8hZ {
  max-width: 100%;
  position: relative;
  z-index: 0;
  left: 50%;
  top: 140px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 700px;
  background-color: #e9e9e9;
}
.Contact_map__2yjiP {
  height: 400px;
  margin-bottom: 30px;
}
.Contact_visit__3FhEv {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 40%;
  margin-left: 15%;
  margin-right: 15%;
}
.Contact_visit__3FhEv h4 {
  color: rgb(0, 186, 254);
  margin-bottom: 30px;
}
.Contact_visit__3FhEv h1 {
  color: rgb(0, 88, 240);
}
.Contact_wave__1fe7p {
  width: 100%;
}
.Contact_infoContact__3EYGn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.Contact_infobox__23ir0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.Contact_iconContact__3ZVfd {
  width: 20%;
}
.Contact_row__3uYU0 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .Contact_background__6P8hZ {
    height: 700px;
  }
  .Contact_contact__1PWNs {
    padding: 1rem;
    width: 30rem;
    top: 3rem;
    left: 20%;
  }
  .Contact_contact__1PWNs h2 {
    margin-left: 13%;
  }
  .Contact_map__2yjiP {
    height: 400px;
  }
  .Contact_visit__3FhEv {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 23%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
  }

  .Contact_row__3uYU0 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .Contact_infobox__23ir0 {
    width: 40%;
  }
}

@media (min-width: 992px) {
  .Contact_visit__3FhEv {
    display: flex;
    flex-direction: row;
    margin-top: 200px;
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media (min-width: 1200px) {
}

.ContactForm_controls__3LtcD {
  width: 100%;
  margin-left: 5%;
  font-size: 12px;
}
.ContactForm_controls__3LtcD label {
  margin-bottom: 0.4rem;
  display: block;
  margin: 0.4rem;
  font-size: 1rem;
}

.ContactForm_controls__3LtcD input {
  padding: 0.4rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 85%;
}
.ContactForm_select__3ixKh {
  padding: 0.4rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 85%;
}
.ContactForm_message__21PqR {
  height: 70px;
}
.ContactForm_action__B6XnD {
  margin: 1rem;
}

.ContactForm_invalid__llT0A input {
  border-color: red;
  background: #ffd7d7;
}

.ContactForm_invalid__llT0A label {
  border-color: red;
}
.ContactForm_button__3v24h {
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

@media (min-width: 768px) {
  .ContactForm_controls__3LtcD {
    margin-left: 13%;
  }
  .ContactForm_controls__3LtcD input {
    max-width: 100%;
  }
}

.Brand_sectionBrand__2G2NJ {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.Brand_brand__26xsT img {
  height: 70px;
}
.Brand_brand__26xsT {
  margin: 5%;
}
.Brand_brandPage__2Z9pn {
  margin-top: 200px;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Brand_brandPage__2Z9pn h1 {
  margin-bottom: 4%;
  margin-top: 4%;
}
.Brand_principalBrand__2h_iG {
  margin: 1%;
}
.Brand_principalBrand__2h_iG img {
  height: 120px;
}
@media (min-width: 768px) {
  .Brand_principalBrand__2h_iG img {
    height: 150px;
  }
}
@media (min-width: 992px) {
  .Brand_principalBrand__2h_iG img {
    height: 200px;
  }
}

.carousel_iconImg__1amm3 {
  width: 50px;
  margin-left: 50px;
}
.carousel_indicators__3REdW {
  display: flex;
  justify-content: center;
}
.carousel_indicator__sZIRU {
  background-color: Transparent;
  border: none;
}

.carousel_indicator__sZIRU:hover {
  color: rgb(0, 156, 254);
}
.carousel_indicators__3REdW > button {
  margin: 5px;
}
.carousel_indicators__3REdW > button.carousel_active__1SiFh {
  background-color: Transparent;
  color: rgb(0, 88, 240);
  border: none;
}
.carousel_normal__1UjOk {
  background-color: Transparent;
  color: rgb(151, 153, 156);
  border: none;
}
.carousel_arrowPrev__3csuJ {
  display: block;
  width: 30px;
  position: absolute;
  z-index: 3;
  left: 12%;
  opacity: 0.5;
  background-color: Transparent;
  border: none;
}
.carousel_arrowNext__2uk7M {
  display: block;
  width: 30px;
  position: absolute;
  z-index: 3;
  left: 80%;
  opacity: 0.5;
  background-color: Transparent;
  border: none;
}
.carousel_arrowPrev__3csuJ:hover {
  opacity: 1;
}
.carousel_arrowNext__2uk7M:hover {
  opacity: 1;
}

.carousel_carImg__2oZ2p {
  width: 300px;
  height: auto;
  box-shadow: 0 10px 17px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  margin: 10px;
}
.carousel_carousel__FPvxc {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
  margin-bottom: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel_mainCarousel__10SDA {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.carousel_carImgsec__2z2fX {
  display: none;
}
h1 {
  color: rgb(0, 88, 240);
}
.carousel_text__2HUNp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}
.carousel_title__3K9Ac {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (min-width: 576px) {
  .carousel_carImg__2oZ2p {
    width: 400px;
  }

  .carousel_arrowPrev__3csuJ {
    left: 12%;
  }
  .carousel_arrowNext__2uk7M {
    left: 80%;
  }
}
@media (min-width: 768px) {
  .carousel_arrowPrev__3csuJ {
    left: 20%;
  }
  .carousel_arrowNext__2uk7M {
    left: 73%;
  }
  .carousel_carImg__2oZ2p {
    width: 400px;
  }
}
@media (min-width: 992px) {
  .carousel_carImgsec__2z2fX {
    display: block;
    width: 500px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    position: relative;
    z-index: 1;
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
  }
  .carousel_carImg__2oZ2p {
    width: 500px;
  }

  .carousel_arrowPrev__3csuJ {
    left: 12%;
  }
  .carousel_arrowNext__2uk7M {
    left: 85%;
  }
}
@media (min-width: 1200px) {
  .carousel_carImgsec__2z2fX {
    width: 380px;
  }
  .carousel_carImg__2oZ2p {
    width: 600px;
  }
}

.Gallery_galleryPage__1OASC {
  margin-top: 140px;
}

.Gallery_carouselblue__2eR8j {
  background-color: #e9e9e9;
}

.WindowImage_total__3_xzC img {
  width: 100%;
}

.WindowImage_total__3_xzC h3 {
  color: #ff9100;
}
.WindowImage_buttonRight__2NPD8 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.Modal_backdrop__2zTpQ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
  overflow-y: hidden;
}

.Modal_modal__2hm7N {
  position: fixed;
  top: 180px;
  left: 10%;
  width: 80%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  -webkit-animation: Modal_slide-down__32OVX 300ms ease-out forwards;
          animation: Modal_slide-down__32OVX 300ms ease-out forwards;
}

@media (min-width: 768px) {
  .Modal_modal__2hm7N {
    width: 60%;
    left: 20%;
    top: 170px;
  }
}
@media (min-width: 992px) {
  .Modal_modal__2hm7N {
    width: 46%;
    left: 27%;
  }
}

@-webkit-keyframes Modal_slide-down__32OVX {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Modal_slide-down__32OVX {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Service_employees__2jNlC {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.Service_employeeSection__2MZSp {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #c6c6c6;
}
.Service_employeeSection__2MZSp h1 {
  color: rgb(3, 63, 167);
  font-size: 25px;
}
.Service_employeeSection__2MZSp h4 {
  color: rgb(0, 186, 254);
  margin-bottom: 5%;
}
.Service_principal__3yNGr {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 576px) {
  .Service_employeeSection__2MZSp h1 {
    font-size: 35px;
  }
}
@media (min-width: 768px) {
  .Service_employeeSection__2MZSp h1 {
    font-size: 40px;
  }
}

.poolCleaning_showProducts__LLCAo {
  display: flex;
  flex-direction: column;
  margin-top: 135px;
}

.poolCleaning_submenu__WRESD {
  width: 20%;
  height: 100%;
}
.poolCleaning_products__3fSkd {
  margin-top: 100px;
}
@media (min-width: 992px) {
  .poolCleaning_showProducts__LLCAo {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .poolCleaning_products__3fSkd {
    width: 100%;
    margin-left: 300px;
    margin-top: 0px;
  }
  .poolCleaning_onlyProducts__3Srkl {
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
  }
}

.Subcategory_menu__acrmO {
  width: 100%;
  position: fixed;
  text-align: center;
  background-color: white;
  padding-top: 10px;
}

.Subcategory_menu__acrmO h4 {
  color: rgb(0, 88, 240);
}

.Subcategory_subMenu__1SmEj {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .Subcategory_menu__acrmO {
    background-color: transparent;
    width: 22%;
    height: 40%;
    text-align: start;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .Subcategory_subMenu__1SmEj {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .Subcategory_menu__acrmO {
    padding-left: 4rem;
    padding-top: 4rem;
    padding-right: 4rem;
  }
}

.submersibleProducts_showProducts__1iiuQ {
  display: flex;
  flex-direction: column;
  margin-top: 134px;
}

.submersibleProducts_submenu__DYuz8 {
  width: 20%;
  height: 100%;
}
.submersibleProducts_products__3ebbQ {
  margin-top: 200px;
}
@media (min-width: 608px) {
  .submersibleProducts_products__3ebbQ {
    margin-top: 140px;
  }
}

@media (min-width: 992px) {
  .submersibleProducts_showProducts__1iiuQ {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .submersibleProducts_products__3ebbQ {
    width: 100%;
    margin-left: 300px;
    margin-top: 0px;
  }
  .submersibleProducts_onlyProducts__2dVMD {
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
  }
}

.surfaceProducts_showProducts__2DkK0 {
  display: flex;
  flex-direction: column;
  margin-top: 135px;
}

.surfaceProducts_submenu__SNx5d {
  width: 20%;
  height: 100%;
}

.surfaceProducts_manyCategories__3nfXI {
  margin-top: 180px;
}
.surfaceProducts_fewCategories__RkWqJ {
  margin-top: 100px;
}
@media (min-width: 992px) {
  .surfaceProducts_showProducts__2DkK0 {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .surfaceProducts_manyCategories__3nfXI {
    margin-left: 300px;
    margin-top: 100px;
    margin-top: 0px;
  }
  .surfaceProducts_fewCategories__RkWqJ {
    margin-left: 300px;
    margin-top: 100px;
    margin-top: 0px;
  }
}

