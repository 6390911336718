.whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #20d767;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 5;
}
.icon {
  color: white;
  font-size: 35px;
}
