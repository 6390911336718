.footer {
  color: white;
  background: linear-gradient(90deg, rgb(0, 37, 142) 50%, rgb(0, 170, 255));

  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}
.contInfo {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;
  margin-top: 2%;
}
.redlogo {
  width: 35px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
}
.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  padding-right: 15%;
  align-items: flex-start;
  justify-content: flex-start;
}
.info h2 {
  font-size: 20px;
}
.info p {
  line-height: 17px;
}
.derechos {
  font-size: 12px;
}
.socialMediaIcons {
  display: flex;
  flex-direction: row;
}
@media (min-width: 576px) {
  .footer {
    height: 300px;
  }
  .info {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  .redlogo {
    width: 50px;
  }
  .derechos {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  .info h2 {
    font-size: 25px;
  }
  .info p {
    line-height: 24px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
