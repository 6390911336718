.productMainInfo {
  margin-top: 200px;
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  align-self: center;
  justify-self: center;
  justify-content: center;
}
.productMainInfo h1 {
  color: rgb(3, 63, 167);
}
.productMainInfo h4 {
  color: rgb(0, 186, 254);
}
