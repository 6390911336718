.sectionBrand {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.brand img {
  height: 70px;
}
.brand {
  margin: 5%;
}
.brandPage {
  margin-top: 200px;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.brandPage h1 {
  margin-bottom: 4%;
  margin-top: 4%;
}
.principalBrand {
  margin: 1%;
}
.principalBrand img {
  height: 120px;
}
@media (min-width: 768px) {
  .principalBrand img {
    height: 150px;
  }
}
@media (min-width: 992px) {
  .principalBrand img {
    height: 200px;
  }
}
