.menu {
  width: 100%;
  position: fixed;
  text-align: center;
  background-color: white;
  padding-top: 10px;
}

.menu h4 {
  color: rgb(0, 88, 240);
}

.subMenu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .menu {
    background-color: transparent;
    width: 22%;
    height: 40%;
    text-align: start;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .subMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .menu {
    padding-left: 4rem;
    padding-top: 4rem;
    padding-right: 4rem;
  }
}
