.iconImg {
  width: 50px;
  margin-left: 50px;
}
.indicators {
  display: flex;
  justify-content: center;
}
.indicator {
  background-color: Transparent;
  border: none;
}

.indicator:hover {
  color: rgb(0, 156, 254);
}
.indicators > button {
  margin: 5px;
}
.indicators > button.active {
  background-color: Transparent;
  color: rgb(0, 88, 240);
  border: none;
}
.normal {
  background-color: Transparent;
  color: rgb(151, 153, 156);
  border: none;
}
.arrowPrev {
  display: block;
  width: 30px;
  position: absolute;
  z-index: 3;
  left: 12%;
  opacity: 0.5;
  background-color: Transparent;
  border: none;
}
.arrowNext {
  display: block;
  width: 30px;
  position: absolute;
  z-index: 3;
  left: 80%;
  opacity: 0.5;
  background-color: Transparent;
  border: none;
}
.arrowPrev:hover {
  opacity: 1;
}
.arrowNext:hover {
  opacity: 1;
}

.carImg {
  width: 300px;
  height: auto;
  box-shadow: 0 10px 17px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  margin: 10px;
}
.carousel {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
  margin-bottom: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainCarousel {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.carImgsec {
  display: none;
}
h1 {
  color: rgb(0, 88, 240);
}
.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}
.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (min-width: 576px) {
  .carImg {
    width: 400px;
  }

  .arrowPrev {
    left: 12%;
  }
  .arrowNext {
    left: 80%;
  }
}
@media (min-width: 768px) {
  .arrowPrev {
    left: 20%;
  }
  .arrowNext {
    left: 73%;
  }
  .carImg {
    width: 400px;
  }
}
@media (min-width: 992px) {
  .carImgsec {
    display: block;
    width: 500px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    position: relative;
    z-index: 1;
    filter: brightness(70%);
  }
  .carImg {
    width: 500px;
  }

  .arrowPrev {
    left: 12%;
  }
  .arrowNext {
    left: 85%;
  }
}
@media (min-width: 1200px) {
  .carImgsec {
    width: 380px;
  }
  .carImg {
    width: 600px;
  }
}
