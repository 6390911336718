.pageCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 65px;
  justify-content: space-evenly;
  text-align: center;
  margin-top: 200px;
}
.templateImg {
  width: 300px;
  margin-left: 5%;
  border-radius: 6px;
}
.pageCard h1 {
  color: rgb(3, 63, 167);
  margin-bottom: 5%;
  font-size: 25px;
}
.pageCard h4 {
  color: rgb(0, 186, 254);
  margin-bottom: 3%;
  font-size: 18px;
}
.pageCard p {
  margin-bottom: 5%;
}
.textInfo {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .templateImg {
    width: 400px;
  }
  .pageCard h1 {
    font-size: 35px;
  }
}
@media (min-width: 768px) {
  .textInfo {
    width: 60%;
  }
  .templateImg {
    width: 500px;
  }
  .pageCard h1 {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .pageCard {
    margin-top: 245px;
    flex-direction: row;
    text-align: left;
  }
  .textInfo {
    width: 45%;
  }
  .templateImg {
    width: 300px;
  }
  .pageCard h4 {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .templateImg {
    width: 500px;
  }
  .pageCard h4 {
    font-size: 24px;
  }
}
