.total img {
  width: 100%;
}

.total h3 {
  color: #ff9100;
}
.buttonRight {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
