.cardMenu {
  border-radius: 6px;
  width: 300px;
  position: relative;
  text-align: center;
  margin: 20px;
  background-color: black;
}
.cardMenu h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
}
.optionImage {
  position: relative;
  top: 0;
  left: 0;
  width: 300px;
  height: auto;
  filter: brightness(60%);
}
.optionIcon {
  width: 70px;
  height: auto;
  position: absolute;
  z-index: 2;
  top: -30px;
  left: 100px;
}
.infoOption {
  position: absolute;
  top: 80px;
  z-index: 2;
  left: 80px;
}

@media (min-width: 992px) {
  .infoOption {
    z-index: 2;
    top: 125px;
    width: 200px;
    left: 15px;
  }
  .cardMenu {
    width: 450px;
    height: auto;
  }
  .optionImage {
    width: 450px;
  }
  .titleOption {
    width: 200px;
    margin-left: 50px;
  }
  .optionIcon {
    width: 100px;
    top: -54px;
    left: 260px;
  }
}
