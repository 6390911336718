.homeHead {
  color: aliceblue;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  z-index: 3;
  padding: 15%;
  margin-top: 7rem;
}
.homeHead h1 {
  color: aliceblue;
  font-size: 25px;
}
.homeHead h5 {
  font-size: 15px;
}

.message {
  margin-bottom: 5%;
}
.aboutUs {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}

.mishisvis {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #e9e9e9;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.mishisvis h4 {
  color: rgb(0, 88, 240);
}

.servicesSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.servicesSection h1 {
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 2%;
  color: #ff9100;
}
.services {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}
.servicesCard {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.servicesLink {
  display: flex;
  flex-direction: row;
}

.servicesCard img {
  width: 35%;
  margin: 5%;
}
.slogan {
  margin: 5%;
  color: rgb(0, 88, 240);
  display: flex;
  align-items: center;
  justify-content: center;
}
.slogan img {
  width: 300px;
}
.principalInfo {
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 30px;
}
.description {
  width: 80%;
}
.description h2 {
  color: #ff9100;
}
.logoALROTEK {
  width: 90%;
}
.black {
  color: black;
  font-size: 0.9rem;
}

@media (min-width: 576px) {
  .homeHead {
    height: 600px;
    padding: 15%;
  }
  .homeHead h1 {
    font-size: 35px;
  }
  .homeHead h5 {
    font-size: 17px;
  }
  .aboutUs {
    margin-top: 145px;
  }
}
@media (min-width: 768px) {
  .homeHead {
    height: 700px;
  }
  .homeHead h1 {
    font-size: 40px;
  }
  .homeHead h5 {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .homeHead {
    height: 800px;
    margin-top: 2rem;
  }
  .aboutUs {
    flex-direction: row;
    justify-content: space-between;
    margin-left: 15%;
    margin-right: 15%;
  }
  .principalInfo {
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0px;
  }
  .mishisvis {
    height: 450px;
    background-color: transparent;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .logoALROTEK {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .homeHead {
    height: 900px;
    margin-top: 2rem;
  }
  .aboutUs {
    margin-bottom: 100px;
  }
  .servicesCard img {
    width: 30%;
    margin: 3%;
  }
}
