.store {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: center;
}
.store h1 {
  display: flex;
  justify-self: center;
  align-self: center;
  margin-top: 15px;
  color: #ff9100;
}
.products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
@media (min-width: 992px) {
  .store h1 {
    margin-top: 40px;
  }
}
