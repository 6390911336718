.employees {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.employeeSection {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #c6c6c6;
}
.employeeSection h1 {
  color: rgb(3, 63, 167);
  font-size: 25px;
}
.employeeSection h4 {
  color: rgb(0, 186, 254);
  margin-bottom: 5%;
}
.principal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 576px) {
  .employeeSection h1 {
    font-size: 35px;
  }
}
@media (min-width: 768px) {
  .employeeSection h1 {
    font-size: 40px;
  }
}
