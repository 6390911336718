.card {
  padding: 1rem;
  margin: 1rem;
  border-radius: 6px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.card img {
  width: 240px;
}
@media (min-width: 576px) {
  .card {
    width: 40%;
    text-align: center;
  }
  .card img {
    margin: 5%;
  }
}
@media (min-width: 768px) {
  .card {
    width: 25%;
    text-align: center;
  }
}
