.fireSystem button {
  display: none;
}
.tankFamilyMainInfo {
  margin-top: 170px;
}

.familyMainInfo {
  margin-top: 170px;
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  align-self: center;
  justify-self: center;
  justify-content: center;
  text-align: center;
}
.familyMainInfo h1 {
  color: rgb(3, 63, 167);
}
.text {
  margin-top: 250px;
}
.employeeSection {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #c6c6c6;
}
.employeeSection h1 {
  color: rgb(3, 63, 167);
  font-size: 25px;
}
.employeeSection h4 {
  color: rgb(0, 186, 254);
  margin-bottom: 5%;
}
.principal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 576px) {
  .familyMainInfo {
    margin-top: 200px;
  }
  .employeeSection h1 {
    font-size: 35px;
  }
}
@media (min-width: 768px) {
  .employeeSection h1 {
    font-size: 40px;
  }
}
