.card {
  padding: 1rem;
  border-radius: 6px;
  background-color: white;
  transition-duration: 0.4s;
  height: 220px;
  width: 360px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  margin-top: 50px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .card {
    height: 200px;
    width: 350px;
  }
}
@media (min-width: 992px) {
  .card {
    height: 200px;
    width: 350px;
  }
}
