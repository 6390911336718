.instagram {
  background-color: white;
  border-radius: 50%;
  bottom: 20px;
  right: 20px;
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin: 15px;
}

.icon {
  color: #0015ad;
  font-size: 27px;
}
.facebook {
  margin: 15px;
}
