.showProducts {
  display: flex;
  flex-direction: column;
  margin-top: 135px;
}

.submenu {
  width: 20%;
  height: 100%;
}

.manyCategories {
  margin-top: 180px;
}
.fewCategories {
  margin-top: 100px;
}
@media (min-width: 992px) {
  .showProducts {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .manyCategories {
    margin-left: 300px;
    margin-top: 100px;
    margin-top: 0px;
  }
  .fewCategories {
    margin-left: 300px;
    margin-top: 100px;
    margin-top: 0px;
  }
}
