.employeePhoto {
  width: 250px;
  border-radius: 6px;
  margin: 10px;
}

.personalInfo {
  width: 250px;
}
.employeePhoto:hover {
  overflow: visible;
  transform: scale(1.1);
  transition: 1s;
}
.employeeCard {
  width: 270px;
}
